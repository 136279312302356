.progress-success-line {
  .ant-progress-success-bg {

  }
  .ant-progress-text {
    display: none;
  }
  &.ant-progress-show-info .ant-progress-outer {
    margin-right: 0;
    padding-right: 0;
  }
}
.send-request-modal {
  .ant-modal-content {
    overflow: hidden;
  }
  .ant-modal-body {
    background-color: #F0F2F5;

    .title-modal-wrapper {
      background-color: #FFFFFF;
      margin: -24px -24px 16px;
      padding: 16px 24px;

      .title-modal {
        font-weight: 500;
        font-size: 21px;
        line-height: 25px;
        text-align: center;
        color: #191F2D;
      }
    }
    .ant-form-item {
      margin: 0;
    }
    .ant-form-item+.ant-form-item {
      margin-top: 16px;
    }
    .ant-form-item-label {
      font-weight: 500;
      line-height: 16px;
      letter-spacing: -0.04em;
      text-transform: capitalize;
      color: #484849;
    }
    .ant-row-flex-end {
      margin-top: 44px;
    }
  }
}
