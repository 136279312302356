body {
  .ant-spin {
    color: #191F2D;
  }
  .ant-spin-dot-item {
    background-color: #191F2D;
  }
  .ant-row::before, .ant-row::after {
    clear: both;
  }
  .ant-select {
    width: 100%;
  }
  .ant-form-item-label {
    line-height: 14px;
    font-size: 14px;
    font-weight: bold;
    padding: 0;
  }

  .ant-form-item-label label {
    color: rgba(0, 0, 0, 0.7);
  }

  .ant-layout {
    min-height: 100vh;
    background-color: #F5F6FA;
  }

  .disable-header.ant-fullcalendar-fullscreen .ant-fullcalendar-header {
    display: none;
  }
  .ant-dropdown-menu-item:hover {
    a {
      color: #191F2D;
    }
  }
  .ant-select-dropdown-menu-item-active,
  .ant-select-dropdown-menu-item:hover,
  .ant-dropdown-menu-item:hover {
    color: #191F2D;
  }
  .ant-fullcalendar-fullscreen .ant-fullcalendar-month:hover,
  .ant-fullcalendar-fullscreen .ant-fullcalendar-date:hover,

  .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover,
  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background-color: rgba(0, 0, 0, 0.02);
  }
  .ant-table-thead > tr > th {
    background-color: transparent;
  }
  .ant-select-dropdown-menu-item-active:hover:not(.ant-select-dropdown-menu-item-disabled) {
    color: #191F2D;
  }
  .ant-select-dropdown-menu-item-selected,
  .ant-select-dropdown-menu-item-selected:hover {
    color: #ffffff;
    background-color: #001529;
  }

  .ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-month, .ant-fullcalendar-fullscreen .ant-fullcalendar-selected-day .ant-fullcalendar-date {
    background: rgba(25, 31, 45, 0.05);
  }
  .ant-breadcrumb > span:last-child {
    font-size: 24px;
    color: #001529;
  }
  .ant-fullcalendar-fullscreen .ant-fullcalendar-date {
    margin: 0;
  }
  .ant-table.ant-table-small {
    background-color: #ffffff;
  }
  .ant-form-item-control {
    line-height: normal;
  }
  .ant-form-item-label {
    margin-bottom: 4px;
  }
  .ant-btn.ant-btn-primary.approve {
    width: 125px;
    border: 1px solid #06900E;
    background: #06900E;
    border-radius: 5px;
  }
  .ant-btn.reject {
    color: #ffffff;
    width: 125px;
    background: #C30B2F;
    border: 1px solid #C30B2F;
    box-sizing: border-box;
    border-radius: 5px;
  }
  .ant-btn.view-more {
    width: 125px;
    color: #5F5F5F;
    border: 1px solid #5F5F5F;
    box-sizing: border-box;
    border-radius: 5px;
  }
  .ant-btn.cancel {
    width: 125px;
    background-color: #E45C46;
    border: rgba(0,0,0,0);
    color: #FFFFFF;
  }
  .ant-table-thead > tr > th, .ant-table th {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: normal;
  }
  .th-large .ant-table-thead > tr > th, .ant-table th {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    color: #5F5F5F;
  }
  .style-table .ant-table-thead > tr > th {
    padding: 10px 16px;
  }
  .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner-full {
    margin-top: -0.7em;
  }
  .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner-full .ant-table-column-sorter-down {
    margin-top: 0;
  }
  .ant-table-thead > tr > th .ant-table-column-sorter {
    padding-left: 5px;
  }
  .calendar-edit .ant-fullcalendar-fullscreen .ant-fullcalendar-content {
    height: 100%;
    width: 100%;
    margin: 0;
  }
  .calendar-edit .ant-fullcalendar-fullscreen .ant-fullcalendar-date {
    display: inline-table;
    width: 100%;
    margin: 0;
  }
  .ant-fullcalendar-fullscreen .ant-fullcalendar-value {
    width: 100%;
    text-align: right;
    padding: 0 5px;
  }

  .ant-modal-body {
    padding: 0;
  }
  .title-modal {
    text-align: center;
    background: #fff;
    margin-bottom: 0;
    padding: 15px 10px;
    font-weight: 500;
    font-size: 21px;
    color: #191F2D;
  }

  .modal-body-wrapper {
    padding: 20px 15px;
    background: #F0F2F5;

    .ant-checkbox-wrapper{
      span{
        font-weight: normal;
      }
    }

    .ant-form-item{
      margin-bottom: 0;
    }
    .ant-form-item+.ant-form-item {
      margin-top: 16px;
    }
    .ant-form-item-label label {
      font-weight: 500;
      line-height: 16px;
      letter-spacing: -0.04em;
      color: #484849;
    }
    .ant-calendar-picker-icon{
      color: #484849;
    }
  }

  .ant-table-thead > tr > th.column-day, .ant-table-tbody > tr > td.column-day{
    text-align: right;
    padding-right: 17%;
  }
  .table-days{
    text-align: right;
    width: 30px;
  }

  .ant-btn-group .ant-btn-primary:last-child:not(:first-child), .ant-btn-group .ant-btn-primary + .ant-btn-primary {
    border-left-color: #6a6a6a
  }
  .ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
    border-right-color: #6a6a6a
  }

  .ant-table-pagination.ant-pagination {
    float: none;
    text-align: center;
  }
  .ant-table-expanded-row:first-child, .ant-table-row-expand-icon-cell {
    max-width: 50px;
    width: 50px;
  }
}

.field-prefix-50 {
  .ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 50px;
  }
}