.avatar-container {
  border-radius: 120px;
  max-width: 120px;
  overflow: hidden;
}
.btn-change-image {
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 4px;
  color: transparent;
  background: transparent;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
}

.avatar-container {
  cursor: pointer;
  position: relative;
  display: inline-block;
  width: 120px;
  height: 120px;
  border-radius: 120px;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.1);
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: ' ';
    display: inline-block;
    background-color: transparent;
    transition: all 0.3s;
  }
}

.mouse-enter, .logo-container:hover {
  .btn-change-image {
    background-color: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    line-height: 32px;
    padding: 0 15px;
  }
  .avatar-container{
    &:before {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
}