.nav-links {
  max-height: 64px;
  vertical-align: top;
}
.top-menu-item {
  text-transform: capitalize;
  font-size: 14px;
  font-weight: normal;
  color: rgba(255, 255, 255, 0.7);
  line-height: 64px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  &-wrap {
    display: inline-block;
  }
  &:after {
    content: '';
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 4px;
    background-color: transparent;
    transition: all 0.3s;
  }
  &.active {
    color: #ffffff;
    text-decoration: none;
    &:after {
      background-color: #ffffff;
    }
  }
  &:hover, &:active {
    color: #ffffff;
  }
}
.logo {
  position: absolute;
  left: 0;
  top: 15px;
  height: 35px;
}

.account-trigger {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}

.account-notification {
  height: 32px;
  display: flex;
  align-items: center;
  margin-right: 30px;
}

.approval{
  position: relative;
  span{
    width: 20px;
    height: 20px;
    background: #E45C46;
    color: #fff;
    text-align: center;
    font-size: 11px;
    position: absolute;
    right: -17px;
    top: -12px;
    line-height: 21px;
    border-radius: 50%;
  }
}

.m-menu-burger {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  right: 0;
  top: 15px;
  width: 30px;
  height: 28px;
  &:before, &:after {
    content: '';
  }
  &:after {
    transform-origin: right center;
  }
  &:before {
    transform-origin: right top;
  }
  &-center, &:before, &:after {
    transition: all .3s;
    display: inline-block;
    height: 4px;
    width: 100%;
    border-radius: 4px;
    background: #ffffff;
    line-height: 4px;
  }
  &.is-active {
    &:before, &:after {
      width: 35px;
    }
    &:before {
      transform: rotate(-45deg);
      margin-right: 13px;
    }
    &:after {
      transform: rotate(45deg);
      margin-right: 12px;
    }
    .m-menu-burger-center {
      transform: rotate(45deg);
    }
  }
}

.m-burger{
  display: inline-block;
  position: absolute;
  top: 60px;
  width: calc(100% + 30px);
  z-index: 10;
  overflow: hidden;
  background-color: transparent;
  opacity: 0;
  transition: opacity, background-color .3s ease-in-out;
  height: 0;
  &.is-active {
    overflow-y: auto;
    opacity: 1;
    background-color: #1A1F2C;
  }

  /* mobile menu items (main section) */
  .logo {
    position: relative;
    top: inherit;
    left: inherit;
  }
  .top-menu-item-logo {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  &.is-active {
    .top-menu-item-logo {
      transition: opacity 0.3s ease-in-out;
      opacity: 1;
    }
  }
  .top-menu-item-logo {
    line-height: initial;
    margin-bottom: 40px;
  }
  .top-menu-item {
    margin: 0 0 20px;
    font-size: 24px;
    line-height: 28px;
    &-wrap {
      display: block;
      line-height: 28px;
      padding: 0 62px;
    }
    &:after {
      display: none;
    }
  }

  // bottom part
  .ant-menu-vertical {
    border: none;
  }
  .ant-menu {
    background: transparent;
  }
  .ant-menu-item:active, .ant-menu-submenu-title:active,
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent;
  }

  .ant-menu-vertical .ant-menu-item {
    &, &:not(:last-child) {
      margin: 0 0 14px 0;
      padding: 0 62px;
      height: initial;
      line-height: 26px;
    }
    & > a {
      display: inline-block;
      color: #BBBCC0;
      font-size: 22px;
      line-height: 26px;
      font-weight: normal;
      vertical-align: top;
      &.active {
        font-weight: 500;
        color: #ffffff
      }
    }
    &.menu-item-logout {
      margin-bottom: 42px;
      margin-top: 68px;
    }
    &.menu-item-profile {
      padding-top: 20px;
    }

    &.menu-item-profile:before {
      position: absolute;
      display: block;
      content: '';
      background: #BBBCC0;
      height: 1px;
      width: 100%;
      opacity: 0.43;
      box-shadow: inset 0 4px 4px rgba(255, 255, 255, 0.25);
      left: 0;
      top: 0;
    }
  }

  .approval span {
    height: 28px;
    width: 28px;
    font-size: 14px;
    line-height: 28px;
    right: -45px;
    top: 0
  }
}

@media screen and (min-width: 641px) and (max-width: 800px) {
  .m-burger {
    width: calc(100% + 100px)
  }
}