:root{
  body {
    font: 300 19px/1.2 "Roboto", sans-serif;
  }
  h2, h3 {
    margin: 0;
  }
}
body {
  .noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .flex-wrap-nowrap {
    flex-wrap: nowrap;
  }
  .white-space {
    white-space: nowrap;
  }
  .col-avatar-md {
    max-width: 64px;
    width: 64px;
  }
  .overflow-auto {
    overflow: auto;
  }
  .ant-form-item.fix-bottom-margin {
    margin-bottom: 0;
  }
  .ant-calendar-range {
    max-width: 100vw;
  }
  .col-actions {
    width: 50px;
    max-width: 50px;
  }
  img {
    image-orientation: from-image;
  }
  .empty-btn {
    border: none;
    background: none;
    box-sizing: border-box;
    box-shadow: none;
    outline: none;
    min-height: 32px;
    display: flex;
    align-items: center;
    line-height: 32px;
  }
  .btn-green {
    outline: none;
    height: 32px;
    line-height: 16px;
    border: none;
    background: #06900E;
    border-radius: 5px;
    font-size: 14px;
    color: #ffffff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  }
  .btn-grey {
    outline: none;
    height: 32px;
    line-height: 16px;
    border: none;
    background: #9B9B9B;
    border-radius: 5px;
    font-size: 14px;
    color: #ffffff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  }
  .btn-red {
    outline: none;
    height: 32px;
    line-height: 16px;
    background: #C30B2F;
    border-radius: 5px;
    border: none;
    font-size: 14px;
    color: #ffffff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  }
  .page-header {
    color: #191F2D;
    font-weight: bold;
    font-size: 24px;
    font-family: Roboto, sans-serif;
    word-break: break-word;
    min-height: 32px;
  }

  hr {
    height: 1px;
    color: #d9d9d9;
    background-color: #d9d9d9;
    border: none;
  }

  .container {
    max-width: 1260px;
    width: 100%;
    padding: 0 15px;
    margin: 0 auto;
  }

  .relative {
    position: relative;
  }

  .hidden {
    display: inline-block;
    width: 0;
    height: 0;
    opacity: 0;
    position: absolute;
    visibility: hidden;
  }

  .flex {
    display: flex;
  }
  .fd-column {
    flex-direction: column;
  }
  .jc-flex-start {
    justify-content: flex-start;
  }

  .jc-center {
    justify-content: center;
  }

  .jc-flex-end {
    justify-content: flex-end;
  }

  .jc-space-between {
    justify-content: space-between;
  }

  .ai-flex-start {
    align-items: flex-start;
  }

  .ai-center {
    align-items: center;
  }

  .ai-flex-end {
    align-items: flex-end;
  }

  .cursor {
    cursor: pointer;
  }
  .cursor-disabled{
    cursor: not-allowed;
  }
  .align-center {
    text-align: center;
  }
  .text-right {
    text-align: right;
  }

  /* BUTTONS */
  .clear {
    border: none;
    background-color: transparent;
    border-radius: 0;
    outline: none;
    box-shadow: none;
  }

  /* SPACES */

  // top
  .mt-0 {
    margin-top: 0;
  }
  .mt-5 {
    margin-top: 5px;
  }
  .mt-10 {
    margin-top: 10px;
  }
  .mt-15 {
    margin-top: 15px;
  }
  .mt-20 {
    margin-top: 20px;
  }
  .mt-30 {
    margin-top: 30px;
  }
  .mt-40 {
    margin-top: 40px;
  }
  .mt-45 {
    margin-top: 45px;
  }
  .mt-50 {
    margin-top: 50px;
  }
  .mt-60 {
    margin-top: 60px;
  }
  .mt-80 {
    margin-top: 80px;
  }
  // bottom
  .mb-0 {
    margin-bottom: 0;
  }
  .mb-10 {
    margin-bottom: 10px;
  }
  .mb-15 {
    margin-bottom: 15px;
  }
  .mb-20 {
    margin-bottom: 20px;
  }
  .mb-25 {
    margin-bottom: 25px;
  }
  .mb-30 {
    margin-bottom: 30px;
  }
  .mb-35 {
    margin-bottom: 35px;
  }
  .mb-40 {
    margin-bottom: 40px;
  }
  .mb-45 {
    margin-bottom: 45px;
  }
  .mb-50 {
    margin-bottom: 50px;
  }
  .mb-60 {
    margin-bottom: 50px;
  }

  .mb--30 {
    margin-bottom: -30px;
  }
  // right
  .mr-auto {
    margin-right: auto;
  }
  .mr-0 {
    margin-right: 0;
  }
  .mr-10 {
    margin-right: 10px;
  }
  .mr-15 {
    margin-right: 15px;
  }
  .mr-20 {
    margin-right: 20px;
  }
  .mr-30 {
    margin-right: 30px;
  }
  .mr-40 {
    margin-right: 40px;
  }
  .mr-50 {
    margin-right: 50px;
  }
  // left
  .ml-auto {
    margin-left: auto;
  }
  .ml-0 {
    margin-left: 0;
  }
  .ml-10 {
    margin-left: 10px;
  }
  .ml-15 {
    margin-left: 15px;
  }
  .ml-20 {
    margin-left: 20px;
  }
  .ml-30 {
    margin-left: 30px;
  }
  .ml-40 {
    margin-left: 40px;
  }
  .ml-50 {
    margin-left: 50px;
  }
  .m-20 {
    margin: 20px 0;
  }
  // horizontal
  .mh-10 {
    @extend .ml-10, .mr-10
  }
  // Padding
  // top
  .pt-10 {
    padding-top: 10px;
  }
  .pt-15 {
    padding-top: 15px;
  }
  .pt-20 {
    padding-top: 20px;
  }
  .pt-25 {
    padding-top: 25px;
  }
  .pt-30 {
    padding-top: 30px;
  }
  .pt-40 {
    padding-top: 40px;
  }
  .pt-50 {
    padding-top: 50px;
  }
  // bottom
  .pb-10 {
    padding-bottom: 10px;
  }
  .pb-15 {
    padding-bottom: 15px;
  }
  .pb-20 {
    padding-bottom: 20px;
  }
  .pb-25 {
    padding-bottom: 25px;
  }
  .pb-30 {
    padding-bottom: 30px;
  }
  .pb-40 {
    padding-bottom: 40px;
  }
  .pb-50 {
    padding-bottom: 50px;
  }
  // right
  .pr-10 {
    padding-right: 10px;
  }
  .pr-15 {
    padding-right: 15px;
  }
  .pr-20 {
    padding-right: 20px;
  }
  .pr-30 {
    padding-right: 30px;
  }
  .pr-40 {
    padding-right: 40px;
  }
  .pr-50 {
    padding-right: 50px;
  }
  // left
  .pl-10 {
    padding-left: 10px;
  }
  .pl-15 {
    padding-left: 15px;
  }
  .pl-20 {
    padding-left: 20px;
  }
  .pl-30 {
    padding-left: 30px;
  }
  .pl-40 {
    padding-left: 40px;
  }
  .pl-50 {
    padding-left: 50px;
  }
  .pt-80 {
    padding-top: 80px;
  }
  .pb-80 {
    padding-bottom: 80px;
  }
  // horizontal
  .ph-10 {
    @extend .pl-10, .pr-10
  }

  .width-100 {
    width: 100%;
  }

  //vertical
  .pv-12 {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .p-20 {
    padding: 20px;
  }
}
/* END SPACES */

/* Custom Style */
.entitlements-table{
  input[type='number']{
    text-align: center;
  }
}
.search-mb0{
  .ant-form-item{
    margin-bottom: 0;
  }
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield!important;
}

.ant-table-content {
  overflow: auto;
}

@media screen and (max-width: 640px){
  .calendar-filter{
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    >*{
      flex-basis: 49%;
    }
    .ant-btn{
      width: 100%;
    }
    .ant-select{
      margin-bottom: 10px;
    }
  }
  body {
    .calendar-filter .ml-15{
      margin: 0;
    }
    .ant-layout-footer, .ant-layout-header {
      padding-left: 15px;
      padding-right: 15px;
    }
    .ant-layout-header {
      height: 60px;
      line-height: 60px;
    }
  }

}
.v-align-top {
  vertical-align: top;
}

.lh-28 {
  line-height: 28px;
}
.text-capitalize {
  text-transform: capitalize;
}

// font size

@function pxToRem($pxSize) {
  @return $pxSize / 16 + rem;
}

@mixin font-size() {

  $fs-list: 14 16 20 21 24 48;

  @each $value in $fs-list {
    .fs-#{$value} {
      font-size: pxToRem($value);
    }
  }
}

@include font-size()
