
h1{
  font-size: 32px;
  font-weight: 400;
}
h3{
  font-size: 19px;
  font-weight: 300;
}
// colors
$red:#E45C46;
$black:#191F2D;
$white:#fff;
$grey:#BCBCBC;
$c-holiday: rgba(255, 0, 0, 0.6);
$c-event: rgba(0, 0, 255, 0.75);
$c-default-grey: #dfdfdf;