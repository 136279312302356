@import "../../../configs/variables";

.wrap-forgot-password {
  .modal-body-wrapper {
    background: #ffffff;
    padding: 0;
  }
  .ant-modal-body {
    padding: 40px 60px;
  }
  .ant-modal{
    padding-bottom: 0px;
  }
}

#forgot-password {
  h1 {
    margin-bottom: 15px;
    font-size: 26px;
    text-align: center;
    color: $black;
  }
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    padding-bottom: 45px;
    color: #BCBCBC;
  }

  input {
    height: 43px;
    border: 1px solid #D1D2D5;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 16px;
    color: $black;
    font-weight: 300;

    &::placeholder {
      color: $black;
      font-weight: 300;
    }
  }

  label {
    color: $black !important;
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 7px;
    display: block;
  }

  .ant-form-item {
    margin-bottom: 25px;
  }

  .ant-btn-primary {
    width: 100%;
    height: 43px;
    font-weight: 500;
    font-size: 16px;
  }

  .btn-form-link {
    margin-top: 30px;
  }
}


@media screen and (max-width: 640px) {
  .wrap-forgot-password {
    .ant-modal-body {
      padding: 30px 40px;
    }
  }

  #forgot-password {
    h1 {
      font-size: 22px;
    }

    p {
      font-weight: 400;
      font-size: 16px;
      padding-bottom: 25px;
    }
  }
}

@media screen and (max-width: 480px) {
  #forgot-password {

    .ant-btn-primary {
      font-size: 15px;
      letter-spacing: 1px;
    }
  }
}

