.dp-table-mobile, .dp-subtable-mobile {
  width: 100%;
  .ant-collapse-content-box,
  .ant-collapse-borderless {
    background-color: transparent;
  }
}
.dp-table-row {
  background: #ffffff;
}
.dp-table-row-td {
  padding: 16px 4px;
}

.dp-table-row-td:first-child {
  padding-left: 16px;
}

.dp-table-row-td:last-child {
  padding-right: 16px;
}

.dp-table-mobile {
  box-shadow: 0 0 25px rgba(206, 212, 237, 0.7);
}

.dp-table-subrow {
  font-size: 14px;
  line-height: 16px;
}

.dp-table-inner-td {
  font-size: 14px;
  line-height: 16px;
  padding: 8px;
  &:first-child {
    padding-left: 16px;
  }
  &:last-child {
    padding-right: 16px;
  }
}

.dp-table-subrow-odd, .dp-table-row-odd {
  background: #EEF0F9;
}