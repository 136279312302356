

.multipleDropdownClassName .ant-picker-dropdown {
  top: 0 !important;
}

.multipleDropdownClassName .ant-picker-cell-selected .ant-picker-cell-inner {
  color: rgba(0, 0, 0, 0.85);

}

.multipleDropdownClassName,
.ant-picker-cell-in-view.ant-picker-cell-today,
.ant-picker-cell-inner::before {
  content: none;
  background: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}


