.today-item {
  background: #FFFFFF;
  display: flex;
  &:nth-child(even) {
    background: #EEF0F9;
  }
  &-preview {
    display: flex;
    align-items: center;
    min-height: 52px;
  }
  &-point {
    position: relative;
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 12px;
    margin: 0 22px 0 20px;
  }
  &-text {
    margin-left: 20px;
    margin-right: 20px;
    min-height: 52px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }
  &-name {
    color: #191F2D;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 1px;
  }
  &-description {
    font-size: 12px;
    line-height: 14px;
  }
}